// Bootstrap main SCSS
@import '../../../node_modules/bootstrap3/dist/css/bootstrap.min.css';
@import "~font-awesome/css/font-awesome.min.css";
@import "~toastr/toastr";
@import "~owl.carousel";

// SCSS compass funtions
@import '../../../node_modules/compass-mixins/lib/compass';
@import '../../../node_modules/compass-mixins/lib/animate';


@import "../../scss/base";
@import "colors";
@import "Components/header";
@import "../css/animate.css";
@import "../css/meanmenu.css";
@import "../css/responsive.css";
@import "../css/style.css";
@import "../css/typography.css";
@import "./Components/toastr";
@import "./Components/search";
@import "./Components/meanmenu";
@import "./Components/cart-modal";
@import "Components/cookie_consent";
@import "~gdpr-cookie/gdpr-cookie.css";

div#page-loader {
  background: #000 url("../img/loader.gif") no-repeat scroll center center;
  opacity: 0.3;
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

#logout {
  //color: $black-color;
  //font-size: 14px;
  //font-weight: 400;

  &:hover {
    color: $site-color;
  }
}

.logo img {
  max-width: 150px;
}

.mb-0 {
  margin-bottom: 0;
}

.slider-text-area {
  padding: 0;
  border-bottom: 1px solid $site-color;
  border-top: 1px solid $site-color;
  line-height: 40px;
  height: 40px;

  .owl-item {
    text-align: center;
    overflow: hidden;
    height: 40px;
    text-overflow: ellipsis;

    > a {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .owl-nav {
    display: none;
  }
}
.fb_customer_chat_bounce_out_v2 {
  display: none;
}

.font-weight-bold {
  font-weight: bold;
}

.color-black {
  color: $black-color;
}

.gdprcookie {
  @include display-flex();
  @include flex-direction(column);
  @include align-items(center);

  background: $popup-bg-color;
  border-radius: 3px;
  box-shadow: 0 0 20px #000;
  transition: all 0.5s ease 0s;
  z-index: 9999;
  width: 100%;
  max-width: 100%;
  color: $black-color;
  right: 0;
  bottom: 0;

  .gdprcookie-intro\/ {
    text-align: center;
    > h1 {
      color: $black-color;
      line-height: inherit;
      padding: 0.5rem 0 0;
    }
  }

  h2 {
    color: $black-color;
  }

  p {
    font-family: inherit;
  }

  .gdprcookie-buttons\/ {
    display: flex;
    justify-content: space-between;

    button {
      background: $black-color;
      border: 0 none;
      color: #e5e5e5;
      font-size: 1.3rem;
      height: 3rem;
      letter-spacing: 0;
      padding: 0 25px;
      margin-right: 3rem;

      &:nth-of-type(2) {
        display: none;
      }
    }
  }

  .gdprcookie-types\/ {
    > ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding: 0;

      li {
        margin-right: 2rem;
        > label {
          padding-left: 13px;
        }
      }
    }
  }
}

.container {
  @media only screen and (min-width: 750px) {
    min-width: 750px;
    max-width: 1920px;
    width: auto;

    &-padding {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }
}

footer .container {
  @media only screen and (min-width: 750px) {
    min-width: 750px !important;
    max-width: 1920px !important;
    width: auto !important;

    &-padding {
      padding-left: 4rem !important;
      padding-right: 4rem !important;
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.container-reset {
  min-width: auto;
  max-width: none;
  width: 1170px;

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    width: 970px;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    min-width: 750px;
    max-width: calc(100% - 5rem);
  }
  @media screen and (max-width: 767px) {
    width: 95%;
  }
}

.d-flex {
  @include display-flex();

  &.flex {
    &-between {
      @include justify-content(space-between)
    }

    &-middle {
      @include align-items(center);
    }
  }
}
