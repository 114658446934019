/*
* ----------------------------------------------------------------------------------------
Author       : DuezaThemes
Author URL   : https://themeforest.net/user/duezathemes
Template Name: DANDY - Multi-Purpose eCommerce Template
Version      : 1.0                                          
* ----------------------------------------------------------------------------------------
*/

/* 
Table Of Contents
=========================
- Default Typography
- Custom Typography
=========================
*/


/* 
--------------------------
- Default Typography
--------------------------
*/

body {
    font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #000;
    letter-spacing: 1.5px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
    font-family: 'Montserrat', sans-serif;
}

h1 {
    font-size: 36px;
    line-height: 70px;
    letter-spacing: 1px;
    margin-bottom: 0px;
}

h2 {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0px;
}

h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0px;
}

h4 {
    font-size: 20px;
    line-height: 27px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 14px;
    line-height: 24px;
}

p {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 28px;
    margin: 0;
}

p img {
    margin: 0;
}

span {
    font-family: 'Montserrat', sans-serif;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}


/* em and strong */

em {
    font: 15px/30px;
}

strong,
b {
    font: 15px/30px;
}

small {
    font-size: 11px;
    line-height: inherit;
}


/*  blockquotes */

blockquote {
    border-left: 0 none;
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0;
    padding-left: 40px;
    position: relative;
}

blockquote::before {
    color: #4e5665;
    content: "";
    font-family: FontAwesome;
    font-size: 36px;
    left: 20px;
    line-height: 0;
    margin: 0;
    opacity: 0.5;
    position: absolute;
    top: 20px;
}

blockquote p {
    font-style: italic;
    padding: 0;
    font-size: 18px;
    line-height: 36px;
}

blockquote cite {
    display: block;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
}

blockquote cite:before {
    content: "\2014 \0020";
}

blockquote cite a,
blockquote cite a:visited {
    color: #8B9798;
    border: none
}


/* abbreviations */

abbr {
    color: #444;
    font-weight: 700;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: .6px;
}

abbr:hover {
    cursor: help;
}


/* links */

a,
a:visited {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    font-size: 12px;
    color: #111;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    outline: 0;
}

a:hover,
a:active {
    color: #ed1d7f;
    text-decoration: none;
    outline: 0;
}

a:focus {
    text-decoration: none;
    outline: 0;
}

p a,
p a:visited {
    line-height: inherit;
    outline: 0;
}

a.active-page {
    color: #e6ae48!important;
}


/* list */

ul,
ol {
    margin-bottom: 0px;
    margin-top: 0px;
}

ul {
    margin: 0;
}

ol {
    list-style: decimal;
}

ol,
ul.square,
ul.circle,
ul.disc {
    margin-left: 0px;
}

ul.square {
    list-style: square outside;
}

ul.circle {
    list-style: circle outside;
}

ul.disc {
    list-style: disc outside;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 0;
}

ul ul li,
ul ol li,
ol ol li,
ol ul li {
    margin-bottom: 0px;
}

li {
    line-height: 18px;
    margin-bottom: 0px;
}

ul.large li {}

li p {}


/* definition list */

dl {
    margin: 12px 0;
}

dt {
    margin: 0;
    color: #11ABB0;
}

dd {
    margin: 0 0 0 20px;
}

button {}


/* 
--------------------------
- Custom Typography
--------------------------
*/


/* Your Custom Typography. */
