@import "mixin";
.letter-capitalize {
  &::first-letter, &:first-letter {
    text-transform: capitalize;
  }
}
.disable-scroll {
  overflow: hidden;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}

.disabled {
  @include opacity();
  cursor: not-allowed !important;
  pointer-events: none !important;
}