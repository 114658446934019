/*
* ----------------------------------------------------------------------------------------
Author       : DuezaThemes
Author URL   : https://themeforest.net/user/duezathemes
Template Name: DANDY - Multi-Purpose eCommerce Template
Version      : 1.0
* ----------------------------------------------------------------------------------------
*/

/* Medium Layout: 1367px. */

@media only screen and (min-width: 1200px) and (max-width: 1367px) {
    .coming-area {
        background: #d8d8d8 url("../img/bg/coming.jpg") no-repeat scroll 70% 0 / cover !important;
    }
}


/* Medium Layout: 1280px. */

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .htr-search .htr-search-content input[type="text"] {
        width: 150px !important;
    }
    .htr-search {
        margin-right: 10px !important;
    }
    .login-or-register {
        margin-right: 10px !important;
    }
    .top-checkout {
        margin-right: 10px !important;
    }
    .menu-area-right > div {
        margin-left: 5px !important;
    }
    .menu ul.menu-list.menu-list > li a {
        padding: 5px 0 35px !important;
    }
    .menu ul.menu-list li .mega-menu-area {
        width: 950px !important;
    }
    .home1-slider-container .nivo-caption {
        top: 10% !important;
    }
    .home1-slider-container .slide1-text .cap-dec {
        margin-bottom: 10px !important;
    }
    .home1-slider-container .slide1-text .cap-dec h3 {
        font-size: 22px !important;
        line-height: 24px !important;
    }
    .home1-slider-container .slide1-text .cap-title h2 {
        font-size: 36px !important;
        line-height: 40px !important;
    }
    .home1-slider-container .slide1-text .cap-details {
        margin-bottom: 40px !important;
    }
    /*home-2*/
    .slide2-text .cap-dec {
        margin-bottom: 15px !important;
    }
    .slide2-text .cap-dec h3 {
        font-size: 20px !important;
        line-height: 22px !important;
    }
    .slide2-text .cap-title {
        margin-bottom: 35px !important;
    }
    .slide2-text .cap-title h2 {
        font-size: 50px !important;
        line-height: 54px !important;
    }
    .dwc-inner .timer-area .cdown {
        margin-right: 5px !important;
        min-width: 75px !important;
    }
    /*cart*/
    .sh-menu-item {
        margin-left: 6% !important;
        width: 27% !important;
    }
    .sh-menu-item p {
        font-size: 14px !important;
    }
    .sh-menu-item span {
        font-size: 24px !important;
        right: 15% !important;
    }
    .sccb-shipping-input label {
        display: block !important;
        float: none !important;
        margin: 5px 0 10px !important;
    }
    .sccb-shipping-input select {
        float: none !important;
    }
    .sccb-discount-subscribe-box .sccb-discount-subscribe {
        float: none !important;
        margin: 15px 0 0 !important;
    }
    /*checkout*/
    .pcb-cuppon-input input[type="text"] {
        float: none !important;
        width: 100% !important;
    }
    .pcb-cuppon-input button {
        float: none !important;
        margin: 15px 0 0 !important;
        width: auto !important;
    }
    /*blog-deatils*/
    .bcl-list li {
        font-size: 12px !important;
        line-height: 34px !important;
    }
    .bcl-list li i {
        margin-right: 0 !important;
    }
}


/* Tablet Layout: 768px. */

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    /*modal*/
    .product-modal.modal-dialog {
        width: 760px !important;
    }
    /*.product-tab-content {*/
    /*    width: 62% !important;*/
    /*}*/
    .product-tab-menu {
        text-align: left !important;
        width: 38% !important;
    }
    .single-product-description-area {
        margin-top: 30px !important;
    }
    /*mdoal-end*/
    .htr-search {
        margin-right: 5px !important;
    }
    /*.login-or-register {*/
    /*    margin-right: 5px !important;*/
    /*}*/
    .top-checkout {
        margin-right: 5px !important;
    }
    .header-top-left .htl-title {
        margin-top: 10px !important;
    }
    .header-top {
        margin-bottom: 10px !important;
    }
    .home1-slider-container .slide1-text .cap-dec {
        margin-bottom: 8px !important;
    }
    .home1-slider-container .slide1-text .cap-dec h3 {
        font-size: 22px !important;
        line-height: 24px !important;
    }
    .home1-slider-container .slide1-text .cap-title {
        margin-bottom: 10px !important;
    }
    .home1-slider-container .slide1-text .cap-title h2 {
        font-size: 40px !important;
        line-height: 42px !important;
    }
    .home1-slider-container .slide1-text .cap-details {
        margin-bottom: 30px !important;
    }
    .home1-slider-container .slide1-text .cap-outer-text {
        top: 100px !important;
    }
    .home1-slider-container .nivoSlider img {}
    .home1-slider-container .nivo-caption {
        top: 8% !important;
    }
    .single-top-banner {
        margin-bottom: 30px !important;
    }
    .single-top-banner .stb-img a img {
        width: 100% !important;
    }
    .feature-item-tab-control {
        text-align: left !important;
    }
    .feature-item-tab-control .fi-tab li {
        margin-left: 0 !important;
        margin-right: 10px !important;
    }
    .feature-item-tab-control .fi-tab li::after {
        display: none !important;
    }
    .single-feature-post .feature-post-content {
        padding: 0 10px !important;
    }
    .footer-desc {
        margin-bottom: 30px !important;
    }
    .footer-links {
        margin-bottom: 30px !important;
    }
    .footer-copyright {
        margin-bottom: 30px !important;
        text-align: center !important;
    }
    .footer-social {
        text-align: left !important;
    }
    .footer-social ul li {
        text-align: center !important;
    }
    /*home-2*/
    .home2-testimonial {
        padding: 0 0 60px !important;
    }
    .h2-testimonial-carousel.owl-carousel .owl-nav .owl-prev {
        left: -35px !important;
    }
    .h2-testimonial-carousel.owl-carousel .owl-nav .owl-next {
        right: -35px !important;
    }
    .home2-slider-container .nivo-caption {
        left: 5% !important;
    }
    .slide2-text .cap-dec {
        margin-bottom: 15px !important;
    }
    .slide2-text .cap-dec h3 {
        font-size: 20px !important;
        line-height: 22px !important;
    }
    .slide2-text .cap-title {
        margin-bottom: 25px !important;
    }
    .slide2-text .cap-title h2 {
        font-size: 34px !important;
        line-height: 40px !important;
    }
    .h2-newsletter-left {
        margin-bottom: 60px !important;
    }
    /*home-3*/
    .home3-slider-container .nivo-caption {
        top: 22% !important;
    }
    .slide3-text .cap-title h2 {
        font-size: 50px !important;
        line-height: 50px !important;
    }
    .slide3-text .cap-title-2 h3 {
        font-size: 50px !important;
        line-height: 50px !important;
    }
    .slide3-text .cap-text {
        margin-bottom: 25px !important;
    }
    .slide3-text .cap-view a {
        padding: 12px 20px !important;
    }
    .h3-upcoming-form-area .h3-upcoming-form-input {
        width: 65% !important;
    }
    .h3-upcoming-banner {
        background-size: contain !important;
        height: 50% !important;
        left: auto !important;
        right: 0 !important;
        top: 45% !important;
        width: 40% !important;
    }
    .single-h3-testimonial {
        padding: 50px 60px 90px !important;
    }
    .single-h3-testimonial::after {
        bottom: 80px !important;
    }
    .h3-testimonial-left {
        padding-left: 0 !important;
        width: 25% !important;
    }
    .h3-testimonial-right {
        padding-left: 20px !important;
        width: 75% !important;
    }
    .h3-newsletter-left {
        padding-left: 65px !important;
    }
    .h3-newsletter-input input[type="text"] {
        padding: 0 10px !important;
    }
    .h3-newsletter-input input[type="submit"] {
        position: inherit !important;
    }
    /*shop*/
    .single-list-item .sli-img {
        width: 37% !important;
    }
    .single-list-item .sli-content {
        width: 63% !important;
    }
    /*cart*/
    .sh-menu-item p {
        font-size: 12px !important;
    }
    .sh-menu-item span {
        font-size: 18px !important;
        right: 15% !important;
    }
    .sh-menu-item::before {
        display: none !important;
    }
    .shopping-cart-content-box {
        margin: 0 0 30px !important;
    }
    /*checkout*/
    .billing-address {
        margin: 0 0 30px !important;
    }
    /*about-us*/
    .why-our-shop {
        margin-top: 60px !important;
    }
    /*blog*/
    .blog-sidebar {
        margin: 60px auto auto !important;
        width: 260px !important;
    }
    /*contact*/
    .contact-form-area {
        padding: 84px 40px 70px !important;
    }
    .single-contact-service {
        padding: 0 0 0 20px !important;
    }
    .scs-icon {
        padding: 25px 0 !important;
        width: 18% !important;
    }
    .scs-icon i {
        font-size: 22px !important;
    }
    .scs-content {
        padding: 0 0 0 10px !important;
        width: 82% !important;
    }
    /*coming*/
    .coming-area {
        background-position: 50% 0 !important;
    }
    /*product-comparison*/
    .single-comparison {
        margin: 0 0 60px !important;
    }
}


/* for h3-testimonial layout. */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .h3-upcoming-banner {
        background-size: contain !important;
        height: 60% !important;
        right: auto !important;
        top: 30% !important;
        width: 45% !important;
    }
}


/*tab*/

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .shop-sidebar-area {
        margin: auto auto 60px !important;
        width: 260px !important;
    }
    .shop-right-sidebar-area .shop-sidebar-area {
        margin: 60px auto auto !important;
        width: 260px !important;
    }
    .single-review .sr-img {
        width: 15% !important;
    }
    .single-review .sr-content {
        width: 85% !important;
    }
}


/* Mobile Layout: 320px. */

@media only screen and (max-width: 767px) {
    .single-featured-item .sfi-content .sfi-price-rating .sfi-price {
        display: flex !important;
        flex-direction: column;
    }
}


/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 481px) and (max-width: 767px) {
    /*modal*/
    .product-modal.modal-dialog {
        display: none !important;
    }

    .header-top {
        padding: 0 !important;
    }
    .header-top .mobile-lang-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem !important;
        padding-top: 0.5rem;
        margin-top: 1rem;
    }
    .header-top .mobile-lang-bar .logo {
        width: 25%;
        margin-left: 1rem;
        margin-right: 15rem;
    }
    .header-top .mobile-lang-bar .logo a {
        color: #000000;
        width: 100% !important;
        font-size: 15px;
    }
    .header-top .mobile-lang-bar .login-or-register > span,
    .header-top .mobile-lang-bar .login-or-register > a,
    .header-top .mobile-lang-bar .top-cart #top_cart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 0.8em;
        padding-right: 1em;
    }
    .header-top .mobile-lang-bar .top-cart #top_cart {
        padding-right: 0;
    }
    .header-top .mobile-lang-bar .top-cart #top_cart p {
        font-size: 12px;
    }
    .header-top .mobile-lang-bar .top-cart #top_cart .fa,
    .header-top .mobile-lang-bar .login-or-register .fa {
        font-size: 1.8rem !important;
    }
    .logo a {
        width: 130px !important;
        display: block !important;
    }
    .logo a img {
        width: 100% !important;
        max-width: 75% !important;
    }
    .login-or-register {
        margin-right: 5px !important;
        position: relative;
    }
    .login-register-content {
        left: -7em !important;
        top: 4em !important;
        width: 290px !important;
    }
    .login-register-content::before {
        left: 8em !important;
    }
    .my-cart {
        right: 1em !important;
        top: 4em !important;
        width: 290px !important;
    }
    .my-cart::before {
        right: 2em !important;
    }

    .header-top-left {
        margin-bottom: 30px !important;
        text-align: center !important;
    }
    .header-top-right {
        margin-bottom: 15px !important;
        text-align: center !important;
    }
    .single-top-banner {
        margin-bottom: 30px !important;
    }
    .single-top-banner .stb-img a img {
        width: 100% !important;
    }
    .feature-item-tab-control {
        text-align: center !important;
    }
    .feature-item-tab-control .fi-tab li {
        margin: 0 18px !important;
    }
    .single-featured-item .sfi-img > a img {
        width: 100% !important;
    }
    .single-b2-item img {
        width: 100% !important;
    }
    .single-feature-post {
        margin: 0 0 30px !important;
    }
    .single-service {
        margin: 0 0 30px !important;
    }
    .footer-desc {
        margin-bottom: 50px !important;
    }
    .footer-links {
        margin-bottom: 50px !important;
    }
    .footer-post-area {
        margin-bottom: 50px !important;
    }
    .footer-copyright {
        margin-bottom: 30px !important;
        text-align: center !important;
    }
    .footer-social {
        margin-bottom: 30px !important;
    }
    .payment-method ul {
        text-align: center !important;
    }
    /*home-2*/
    .home-2-feature-item-tab-control .fi-tab li.active a {
        font-size: 12px !important;
    }
    .deals-week-content {
        top: 140px !important;
    }
    .h2-newsletter-left {
        margin-bottom: 50px !important;
    }
    /*home-3*/
    .feature-pro-carousel.owl-carousel .owl-nav {
        display: none !important;
    }
    .h3-upcoming-banner {
        display: none !important;
    }
    .home3-upcoming .timer-area {
        text-align: center !important;
    }
    .h3-newsletter-left {
        margin-bottom: 60px !important;
    }
    /*shop*/
    .shop-sidebar-area {
        margin: auto auto 60px !important;
        width: 260px !important;
    }
    .shop-menu > div {}
    .shop-tab-option {
        float: none !important;
    }
    .default-sorting select,
    .show-product select {
        margin-bottom: 30px !important;
        width: 100% !important;
    }
    .shop-pagination-area .pagination-showing {
        float: none !important;
    }
    .single-list-item .sli-img {
        width: 100% !important;
    }
    .single-list-item .sli-content {
        padding: 30px 30px 30px 0 !important;
        width: 100% !important;
    }
    .sli-review-color .sli-color {
        float: none !important;
    }
    /*shop-right-sidebar*/
    .shop-right-sidebar-area .shop-sidebar-area {
        margin: 60px auto auto !important;
        width: 260px !important;
    }
    /*single-product*/
    .related-pro-carousel .owl-nav > div {
        display: none !important;
    }
    .single-review .sr-img {
        width: 20% !important;
    }
    .single-review .sr-content {
        width: 80% !important;
    }
    /*shopping-cart*/
    .sh-menu-item {
        display: block !important;
        margin-bottom: 10px !important;
        margin-left: 0 !important;
        width: 85% !important;
    }
    .sh-menu-item p {
        font-size: 16px !important;
    }
    .sh-menu-item span {
        font-size: 18px !important;
        right: 12% !important;
    }
    .scca-head .scb-update {
        float: none !important;
        margin: 15px 0 0 !important;
    }
    .shopping-cart-content-box {
        margin-bottom: 30px !important;
    }
    .sccb-discount-subscribe-box input[type="text"] {
        width: 100% !important;
    }
    .sccb-discount-subscribe-box .sccb-discount-subscribe {
        float: none !important;
        margin: 15px 0 0 !important;
    }
    /*checkout*/
    .billing-address-input {
        margin-bottom: 40px !important;
    }
    .billing-address-input label {
        display: block !important;
        float: none !important;
        margin: 0 0 15px !important;
    }
    .billing-address-input select,
    .billing-address-input input[type="text"] {
        display: block !important;
        float: none !important;
        width: 100% !important;
    }
    .cbab-account-password input[type="password"] {
        float: none !important;
        width: 100% !important;
    }
    .pcb-cuppon-input input[type="text"] {
        margin: 0 0 20px !important;
        width: 100% !important;
    }
    .pcb-cuppon-input button {
        float: none !important;
        width: auto !important;
    }
    /*about-us*/
    .why-our-shop {
        margin-top: 60px !important;
    }
    /*blog*/
    .blog-sidebar {
        margin: 60px auto auto !important;
        width: 260px !important;
    }
    /*blog-details*/
    .single-blog-comment .sbc-img {
        width: 18% !important;
    }
    .single-blog-comment .sbc-content {
        width: 82% !important;
    }
    .single-blog-comment.sbc-second {
        margin-left: 40px !important;
    }
    /*contact*/
    .contact-form-area {
        padding: 84px 20px 70px !important;
    }
    .contact-form-head h1 {
        font-size: 50px !important;
    }
    /*404*/
    .error-inner {
        left: 0 !important;
    }
    .error-title {
        font-size: 110px !important;
        line-height: 100px !important;
    }
    .error-search input[type="text"] {
        width: 290px !important;
    }
    /*coming*/
    .coming-area {
        background-position: 45% 0 !important;
    }
    .coming .timer-area .cdown {
        margin: 0 5px !important;
    }
}


/* Wide Mobile Layout: 480px. */

@media only screen and (min-width: 240px) and (max-width: 480px) {
    .footer-top {
        padding-top: 20px !important;
    }
    .footer-top .logo-footer {
        margin-bottom: 20px;
    }
    .header-top {
        padding: 0 !important;
    }
    .header-top .mobile-lang-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1em !important;
        padding-top: 0.5em;
    }
    .header-top .mobile-lang-bar .logo {
        width: 34%;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .header-top .mobile-lang-bar .logo a {
        width: 130px !important;
        display: block !important;
    }
    .header-top .mobile-lang-bar .logo a img {
        width: 100% !important;
        max-width: 85% !important;
    }

    .header-top .mobile-lang-bar .logo a {
        color: #000000;
        width: 100% !important;
        font-size: 15px;
    }
    .header-top .mobile-lang-bar .login-or-register > span,
    .header-top .mobile-lang-bar .login-or-register > a,
    .header-top .mobile-lang-bar .top-cart #top_cart {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 10px !important;
    }

    .header-top .mobile-lang-bar .top-cart #top_cart .fa,
    .header-top .mobile-lang-bar .login-or-register .fa {
        font-size: 2.8rem !important;
    }
    /*.language .menu-right-dropdown {*/
    /*    left: 50px;*/
    /*    width: 80px;*/
    /*    top: 50px;*/
    /*}*/
    /*.language > a::after {*/
    /*    content: "" !important;*/
    /*    font-family: FontAwesome !important;*/
    /*    position: absolute !important;*/
    /*    right: 10px !important;*/
    /*    top: 0px !important;*/
    /*}*/
    /*.language .active-list {*/
    /*    visibility: visible !important;*/
    /*    opacity: 1 !important;*/
    /*    left: -27px !important;*/
    /*}*/
    /*modal*/
    .product-modal.modal-dialog {
        width: 95% !important;
    }
    /*home1*/
    .header-top-left {
        text-align: center !important;
    }
    .header-top-right {
        margin-bottom: 10px !important;
        text-align: center !important;
    }
    .login-or-register, .top-cart {
        margin-top: 1rem;
        margin-right: 0.3rem !important;
        position: relative;
    }
    .top-checkout {
        margin-right: 10px !important;
    }
    .htr-search {
        display: none !important;
    }
    .login-register-content {
        left: -7em !important;
        top: 4em !important;
        width: 290px !important;
    }
    .login-register-content::before {
        left: 8em !important;
    }
    .my-cart {
        right: 1em !important;
        top: 4em !important;
        width: 290px !important;
    }
    .my-cart::before {
        right: 2em !important;
    }
    .home1-slider-container .nivo-directionNav a {
        height: 30px !important;
        line-height: 30px !important;
        width: 30px !important;
    }
    .single-top-banner .stb-img a img {
        width: 100% !important;
    }
    .single-top-banner {
        margin-bottom: 30px !important;
    }
    .feature-item-tab-control {
        text-align: left !important;
    }
    .special-offer .timer-area .cdown {
        margin-bottom: 30px !important;
    }
    .single-feature-post .feature-post-content {
        padding: 0 10px !important;
    }
    .single-feature-post {
        margin-bottom: 30px !important;
    }
    .single-service {
        margin-bottom: 30px !important;
    }
    .newsletter-form input[type="email"] {
        width: 160px !important;
    }
    .newsletter-form input[type="submit"] {
        padding: 0 15px !important;
    }
    .footer-desc {
        margin-bottom: 50px !important;
    }
    .footer-links {
        margin-bottom: 50px !important;
    }
    .footer-post-area {
        margin-bottom: 50px !important;
    }
    .footer-copyright {
        margin-bottom: 30px !important;
        text-align: center !important;
    }
    .footer-social {
        margin-bottom: 30px !important;
    }
    .payment-method ul {
        text-align: center !important;
    }
    .single-tst .single-tst-content .tst-pro-name {
        font-size: 12px !important;
        line-height: 14px !important;
    }
    .single-tst .single-tst-content .tst-price {
        font-size: 12px !important;
    }
    .single-tst .single-tst-content .tst-price .tst-old-price {
        font-size: 12px !important;
    }
    /*home-2*/
    .home-2-feature-item-tab-control .fi-tab li.active a {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .deals-week-img {
        top: -70px !important;
    }
    .dwc-inner .timer-area .cdown {
        margin-bottom: 30px !important;
    }
    .h2-tst-title::after {
        display: none !important;
    }
    .feature-post-carousel.owl-carousel .owl-nav {
        display: none !important;
    }
    .home-2-top-sale-trend-area {
        padding: 223px 0 0 !important;
    }
    .single-feature-post .feature-post-content .fp-meta > span {
        margin-right: 5px !important;
    }
    .h2-newsletter-left {
        padding: 0 10px !important;
    }
    .h2-newsletter-right {
        padding: 0 10px !important;
        margin-top: 30px !important;
    }
    .h2-newsletter-right input[type="email"] {
        width: 100% !important;
    }
    /*home-3*/
    .feature-pro-carousel.owl-carousel .owl-nav > button {
        display: none !important;
    }
    .h3-upcoming-banner {
        display: none !important;
    }
    .home3-upcoming .timer-area {
        text-align: center !important;
    }
    .home3-upcoming .timer-area .cdown {
        margin-bottom: 10px !important;
    }
    .h3-newsletter-left::before {
        font-size: 20px !important;
        line-height: 20px !important;
    }
    .h3-newsletter-left {
        margin-bottom: 60px !important;
        padding-left: 40px !important;
    }
    .h3-newsletter-input input[type="text"] {
        padding: 0 10px !important;
    }
    .h3-newsletter-input input[type="submit"],
    .h3-newsletter-input > a {
        left: 50% !important;
        right: 0 !important;
        transform: translate(-55%, 0) !important;
        text-align: center !important;
    }
    /*shop*/
    .shop-sidebar-area {
        margin-bottom: 60px !important;
    }
    .shop-menu > div {
        display: block !important;
        width: 100% !important;
    }
    .default-sorting select,
    .show-product select {
        margin-bottom: 30px !important;
        width: 100% !important;
        float: none !important;
    }
    .shop-pagination-area .pagination-showing {
        float: none !important;
    }
    .single-list-item .sli-img {
        width: 100% !important;
    }
    .single-list-item .sli-content {
        padding: 30px 30px 30px 0 !important;
        width: 100% !important;
    }
    .sli-review-color .sli-color {
        float: none !important;
    }
    /*shop-right-sidebar*/
    .shop-right-sidebar-area .shop-sidebar-area {
        margin-top: 60px !important;
    }
    /*single-product*/
    .crumb-list {
        display: block !important;
    }
    .crumb-name {
        display: none !important;
    }
    .product-modal button.close {
        top: 14px !important;
    }
    .product-modal #mobile-title {
        margin-bottom: 15px !important;
    }
    .product-tab-content {
        width: 100% !important;
        height: auto !important;
    }
    .product-tab-menu {
        float: left !important;
        margin-top: 10px !important;
        text-align: center !important;
        width: 100% !important;
    }
    .product-tab-menu .product-tab li {
        display: inline-block;
        margin-bottom: 30px;
        width: 30%;
    }
    .spd-add-cart .quantity {
        margin-bottom: 10px !important;
    }
    .spt-tab li {
        margin-bottom: 10px !important;
        width: 100% !important;
    }
    .single-review .sr-img {
        border-right: 0 none !important;
        width: 100% !important;
    }
    .single-review .sr-content {
        padding: 0 !important;
        width: 100% !important;
    }
    .related-pro-carousel .owl-nav > div {
        display: none !important;
    }
    .related-pro-title::after {
        background: #e5e5e5 none repeat scroll 0 0 !important;
        content: "" !important;
        display: none !important;
        height: 1px !important;
        position: absolute !important;
        right: -110% !important;
        top: 15px !important;
        width: 100% !important;
        z-index: 0 !important;
    }
    /*shopping-cart*/
    .sh-menu-item {
        display: block !important;
        margin-bottom: 10px !important;
        margin-left: 0 !important;
        width: 85% !important;
    }
    .sh-menu-item p {
        font-size: 16px !important;
    }
    .sh-menu-item span {
        font-size: 18px !important;
        right: 12% !important;
    }
    .scca-head .scb-update {
        float: none !important;
        margin: 15px 0 0  !important;
    }
    .sccb-shipping-input label {
        display: block !important;
        float: none !important;
    }
    .sccb-shipping-input select {
        float: none !important;
        margin: 10px 0 0 !important;
    }
    .shopping-cart-content-box {
        margin-bottom: 30px !important;
    }
    .sccb-discount-subscribe-box input[type="text"] {
        width: 100% !important;
    }
    .sccb-discount-subscribe-box .sccb-discount-subscribe {
        float: none !important;
        margin: 15px 0 0 !important;
    }
    /*checkout*/
    .billing-address-input {
        margin-bottom: 40px !important;
    }
    .billing-address-input label {
        display: block !important;
        float: none !important;
        margin: 0 0 15px !important;
    }
    .billing-address-input select,
    .billing-address-input input[type="text"],
    .billing-address-input input[type="email"],
    .billing-address-input input[type="tel"] {
        display: block !important;
        float: none !important;
        width: 100% !important;
    }
    .cbab-account-password input[type="password"] {
        float: none !important;
        width: 100% !important;
    }
    .pcb-cuppon-input input[type="text"] {
        margin: 0 0 20px !important;
        width: 100% !important;
    }
    .pcb-cuppon-input button {
        float: none !important;
        width: auto !important;
    }
    /*about-us*/
    .why-our-shop {
        margin-top: 60px !important;
    }
    .single-customer-review .cr-img {
        width: 100% !important;
    }
    .single-customer-review .cr-content {
        width: 100% !important;
    }
    /*blog*/
    .blog-sidebar {
        margin: 60px 0 0 !important;
    }
    /*blog-details*/
    .blog-social {
        float: none !important;
    }
    .single-blog-comment .sbc-img {
        width: 40% !important;
    }
    .single-blog-comment .sbc-content {
        padding: 0 !important;
        width: 100% !important;
    }
    .single-blog-comment.sbc-second {
        margin-left: 0 !important;
    }
    /*contact*/
    .contact-form-pre-text {
        padding: 0 !important;
    }
    .contact-form-area {
        padding: 0px 10px 70px !important;
    }
    .contact-area {
        padding-top: 30px !important;
    }
    .contact-form-head h1 {
        display: none !important;
    }
    .contact-form-head h2 {
        font-size: 22px !important;
    }
    .single-contact-service {
        padding: 0 15px !important;
    }
    .scs-content {
        padding: 0 0 0 15px !important;
    }
    .home3-newsletter-area {
        height: calc(100vw/1.13) !important;
    }
    /*404*/
    .error-inner {
        left: 0 !important;
    }
    .error-title {
        font-size: 80px !important;
        line-height: 70px !important;
    }
    .error-search input[type="text"] {
        width: 230px !important;
    }
    /*coming*/
    .comingtitle2::after {
        display: none !important;
    }
    .coming .timer-area .cdown {
        margin: 5px !important;
    }
}
