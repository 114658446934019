// SCSS compass funtions
@import '../../../node_modules/compass-mixins/lib/compass';
@import '../../../node_modules/compass-mixins/lib/animate';

$site-color: #000000;
$gray-color: #a6a6a6;
$gray-light-color: #efefef;
$black-color: #000000;
$warm-black-color: #232530;
$white-color: #ffffff;
$popup-bg-color: #f4f4f4;
$dark-gray-color: #2c2c2c;
$extra-color: #ed1d7f;
