.mcp {
  &-pro {
    &-price {
      display: flex;
      flex-direction: column;
      align-items: end;
    }

    &-quantity {
      line-height: 25px !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.my-cart-total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cost {
    display: flex !important;
    flex-direction: column;
    align-items: end;
  }
}
