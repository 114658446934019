@import '../colors';

.header-top {
  .menu-area-left{
    a {
      position: relative;
      padding: 0 8px 0 0;
      color: $black-color;
      font-size: 15px;

      &:hover::after {
        transform: scaleX(1);
      }

      &::after {
        background: #000000 none repeat scroll 0 0;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0px;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0 0 0;
        transition: all 0.3s ease 0s;
        width: 20px;
      }
    }
  }
  .header-top-right{

    #login_register, #top_cart, #logout {
      position: relative;
      padding: 6px 5px 6px 0;

      &:hover::after {
        transform: scaleX(1);
      }

      &::after {
        background: #000000 none repeat scroll 0 0;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0px;
        position: absolute;
        transform: scaleX(0);
        transform-origin: 0 0 0;
        transition: all 0.3s ease 0s;
        width: 20px;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
      padding: 0 !important;

      .mobile-lang-bar {
        @include display-flex();
        @include align-items(center);
        @include justify-content(space-between);
        margin-top: 1rem;
        margin-bottom: 1em !important;
        padding-top: 0.5em;

        .logo {
          width: 34%;
          margin-left: 1rem;
          margin-right: 13rem;

          a {
            color: #000000;
            width: 100% !important;
            font-size: 15px;

            img {
              width: 100% !important;
              max-width: 40% !important;
            }
          }
        }

        .login-or-register {
          margin-right: 5px !important;
          position: relative;
        }

        .login-register-content {
          left: -7em !important;
          top: 4em !important;
          width: 290px !important;

          &::before {
            left: 8em !important;
          }
        }

        .login-or-register,
        .top-cart {
          > span,
          > a,
          > #top_cart {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 0.8em !important;
            padding-right: 1em;
          }

          #top_cart {
            padding-right: 0;
          }

          .fa {
            font-size: 2rem !important;
          }
        }

        //.login-or-register > span,
        //.login-or-register > a,
        //.top-cart #top_cart {
        //  display: flex;
        //  justify-content: center;
        //  align-items: center;
        //  flex-direction: column;
        //  font-size: 0.8em;
        //  padding-right: 1em;
        //}

        .top-cart {
          #top_cart {
            padding-right: 0;
          }
        }
    }

    //.logo a {
    //  width: 130px !important;
    //  display: block !important;
    //}
    //.logo a img {
    //  width: 100% !important;
    //  max-width: 75% !important;
    //}
    //.login-or-register {
    //  margin-right: 5px !important;
    //  position: relative;
    //}
    //.login-register-content {
    //  left: -7em !important;
    //  top: 4em !important;
    //  width: 290px !important;
    //}
    //.login-register-content::before {
    //  left: 8em !important;
    //}
  }
}
