#cookie-consent-modal {
  .product-modal {
    width: 900px;

    .single-product-description-area {
      padding: 0 15px;

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        margin-top: 0 !important;
      }

      .modal-header {
        display: flex;
        align-items: center;
        padding: 0;
        border-bottom: none;

        .spd-title {
          font-size: 2rem;
          flex: 1;
          margin-right: 15px;

          @media only screen and (min-width: 200px) and (max-width: 400px) {
            font-size: 1.3rem;
            font-weight: 600;
          }
        }

        .close {
          width: auto;
          height: auto;
          padding: 0.9rem 1.3rem;
          font-size: 1.2rem;
          font-weight: 500 !important;
          position: relative;
          top: unset !important;
          right: unset !important;
        }
      }

      .single-product-tab {
        border: none;

        @media only screen and (min-width: 750px) and (max-width: 1024px) {
          width: 98% !important;
        }
      }

      .single-product-tab-area {
        padding: 0;
      }
    }

    .panel {
      border-radius: 0;
      padding: 0;

      .panel-heading {
        background-color: #000;
        color: #fff;
      }

      .panel-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (min-width: 200px) and (max-width: 400px) {
          display: block;

          a {
            display: block;
            width: 100%;
          }
        }

        p {
          font-size: 1.5rem;
        }

        a {
          background: transparent;
          padding: 0;
          color: #fff;
          font-weight: 600;
          text-transform: none;
          font-size: 1.3rem;
        }
      }

      .panel-collapse {
        max-height: 350px;
        overflow-y: scroll;

        .panel-body {
          h4 {
            font-size: 1.7rem;
            margin-bottom: 1rem;
          }
        }
      }
    }

    @media only screen and (min-width: 481px) and (max-width: 767px) {
      display: block !important;
      width: 95% !important;
    }
  }

  .card {
    border: 1px solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;

    &-body {
      color: #000;
    }
  }

  .cookie-consent {
    &-read-more {
      color: #000000;
      display: block;
      font-size: 14px;
      letter-spacing: 0;
      padding-left: 55px;
      position: relative;
      margin-left: 70px;
    }
  }

  .bottom-text-link {
    a {
      font-size: 15px;
      font-weight: 700;
    }
  }
}
