.mean-container {
  .mean-bar {
    &:after {
      content: none;
    }

    .mean-nav {
      ul {
        li {
          a {
            &:hover {
              color: $black-color;
              font-weight: 600;
            }
          }
        }
      }
    }

    .search-lang-bar {
      display: flex;
      align-items: center;
      justify-content: end;

      .search-opener-mobile {
        margin-right: 1em;
        background: transparent;
        border: none;
        font-size: 1.2em;
        color: #fff;
        height: 2em;
        width: 2em;
      }
      .language {
        background: $popup-bg-color;
        width: 4em;
        position: relative;

        #current_language {
          &:after {
            content: none !important;
          }
        }

        .menu-right-dropdown {
          left: 50px;
          width: 0;
          top: 50px;

          &.active-list {
            visibility: visible !important;
            opacity: 1 !important;
            left: -28px !important;
            text-align: revert;
            width: 4em;
          }
        }
      }
    }
  }
}
