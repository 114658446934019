.search-area {
  position: absolute;
  display: none;
  top: -3px;

  &.show {
    display: block;
  }
  .search-form {
    position: relative;

    input[type="text"] {
      border: 1px solid #c0c5d9;
      color: #161d29;
      font-size: 15px;
      height: 40px;
      letter-spacing: 0;
      padding: 0 10px;
      width: 100%;
    }

    button {
      border: 0 none;
      height: 34px;
      color: #fff;
      position: absolute;
      right: 4px;
      top: 3px;
      width: 60px;

      &.search-submit {
        background: $site-color none repeat scroll 0 0;
        right: 70px;
      }

      &.search-close {
        background: $dark-gray-color none repeat scroll 0 0;
      }
    }
  }
}

.mean-container {
  position: relative;

  .mean-bar {
    .search-opener {
      width: 40px;
      height: 30px;
      line-height: 30px;
      margin-top: 3px;
    }
  }

  .search-area {
    top: 6px;
    padding: 0;

    .search-form {
      button {
        width: 40px;

        &.search-submit {
          right: 55px;
        }
      }
    }
  }
}
